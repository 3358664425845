import axios from "axios";

export const searchInPosts = async (searchTerm) => {
  const results = await axios.get(
    `https://${process.env.GATSBY_WP_DOMAIN || "old.travelnursing.org"}/wp-json/wp/v2/posts?per_page=100&search=${encodeURIComponent(
      searchTerm,
    )}&_embed`,
  );

  return results.data;
};

export const searchInPages = async (searchTerm) => {
  const results = await axios.get(
    `https://${process.env.GATSBY_WP_DOMAIN || "old.travelnursing.org"}/wp-json/wp/v2/pages?per_page=100&search=${encodeURIComponent(
      searchTerm,
    )}&_embed`,
  );

  return results.data;
};

export const searchPostsAndPages = async (searchTerm) => {
  return await Promise.all([
    searchInPosts(searchTerm),
    searchInPages(searchTerm),
  ]).then(([posts, pages]) => {
    return [...posts, ...pages];
  });
};
