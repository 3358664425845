export const getQueryParam = (param) => {
  const search = window.location.search;
  if (search.length <= 1) {
    return null;
  }
  const paramObj = {};
  search
    .slice(1)
    .split("&")
    .forEach((p) => {
      const [key, value] = p.split("=");
      let paramValue = "";
      try {
        paramValue = decodeURIComponent(value.replace(/\+/g, "%20"));
        paramObj[key] = paramValue;
      } catch (e) {
        // ignore the param
      }
    });

  if (param in paramObj) {
    return paramObj[param] == null ? "" : paramObj[param];
  }

  return null;
};
