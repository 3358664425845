import React, { Component } from "react";
import { graphql, Link, navigate } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import PostGridItem from "../components/PostGridItem";
import SearchBar from "../components/SearchBar";
import Seo from "../components/Seo";
import PostGrid from "../ui/PostGrid";
import get from "lodash/get";
import { getQueryParam } from "../utils/params";
import { searchPostsAndPages } from "../utils/wordpressSearch";

const ResultsMessage = styled.div`
  color: #081a31;
  font-family: "Montserrat", "Open Sans", arial, sans-serif;
  text-align: center;

  p {
    margin-bottom: 40px;
  }

  strong {
    font-weight: 700;
  }
`;

class SearchResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      maxVisibleResults: 12,
      query: "",
    };
  }

  componentDidMount() {
    this.setState({ query: getQueryParam("search") }, this.search);
  }

  componentDidUpdate() {
    const query = getQueryParam("search");
    if (query !== this.state.query) {
      this.setState({ query }, this.search);
    }
  }

  search = () => {
    const { query } = this.state;

    if (query.length > 0) {
      this.setState({ isSearching: true });

      searchPostsAndPages(query.trim())
        .then((results) => {
          this.setState({
            results: this.filterResults(results),
            isSearching: false,
            maxVisibleResults: 12,
          });
        })
        .catch((error) => {
          // TODO display an error message?
          console.error(error);
          this.setState({ isSearching: true });
        });
    }
  };

  filterResults = (results) => {
    return results.filter(
      (result) => result.template !== "template-landing-page.php",
    );
  };

  loadMore = () => {
    this.setState({
      maxVisibleResults: this.state.maxVisibleResults + 6,
    });
  };

  updateResults = (results, query) => {
    // Leaving out pages that use the "template-landing-page.php" template.
    // Ideally we'll filter this out on the bootstrap phase,
    // but it's not supported at this moment.
    this.setState({
      results: results.filter(
        (result) => result.template !== "template-landing-page.php",
      ),
      query,
    });
  };

  updateQuery = (query) => {
    navigate(`/search-results/?search=${encodeURIComponent(query)}`);

    if (query.length > 0) {
      this.setState({ query }, this.search);
    }
  };

  getStatusMessage = () => {
    const { isSearching, results, query } = this.state;
    let message;

    if (isSearching) {
      message = (
        <p>
          Looking for articles related to <strong>{this.state.query}</strong>...
        </p>
      );
    } else if (results.length === 0 && query.length > 0) {
      message = (
        <p>
          We couldn't find any results for “<strong>{query}</strong>”.
        </p>
      );
    } else if (results.length > 0 && query.length > 0) {
      message = (
        <p>
          Search results for: <strong>{query}</strong>
        </p>
      );
    }

    return <ResultsMessage>{message}</ResultsMessage>;
  };

  render() {
    const { results, maxVisibleResults, isSearching } = this.state;
    const { data } = this.props;
    const visibleResults = results.slice(0, maxVisibleResults);

    return (
      <Layout templateName="search-results">
        <Seo title="Search Results - Travel Nursing" />
        <SearchBar
          isSearchResultsPage
          performSearch={this.updateQuery}
          isSearching={isSearching}
        />

        {this.getStatusMessage()}

        {!isSearching && (
          <PostGrid>
            {visibleResults.map((result, index) => {
              return (
                <PostGridItem
                  key={`${result.id}-${index}`} // Some results could have the same id
                  slug={result.slug} // TODO: this need to match exactly our routes
                  title={result.title.rendered}
                  imageUrl={get(
                    result,
                    "_embedded.wp:featuredmedia[0].source_url",
                  )}
                  date={new Date(result.date).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                  content={result.content.rendered}
                />
              );
            })}
            {results.length > visibleResults.length && (
              <button
                className="load-more btn--tertiary text-black"
                onClick={this.loadMore}
              >
                Load More
              </button>
            )}
          </PostGrid>
        )}

        {!isSearching && visibleResults.length === 0 && (
          <>
            <h2>Check out our latest articles:</h2>
            <PostGrid>
              {data.relatedArticles.edges.map(({ node }, index) => {
                return (
                  <PostGridItem
                    key={`${node.id}-${index}`} // Some results could have the same id
                    slug={node.slug}
                    title={node.title}
                    fluidImage={get(
                      node,
                      "featured_media.localFile.childImageSharp.fluid",
                      data.placeholderImage.childImageSharp.fluid,
                    )}
                  />
                );
              })}

              {results.length === 0 && (
                <div className="load-more">
                  <Link to="/blog/2/" className="btn--tertiary text-black">
                    View More
                  </Link>
                </div>
              )}
            </PostGrid>
          </>
        )}
      </Layout>
    );
  }
}

export default SearchResults;

export const query = graphql`
  query NoResultsSearchQuery {
    relatedArticles: allWordpressPost(limit: 9) {
      edges {
        node {
          title
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "default.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
